<template>
  <RightSideBar
    v-if="openModal"
    :loading="loading"
    @submit="handleSubmit"
    submit="Submit"
    @close="handleClose"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        Suspend Employee
      </p>
    </template>
    <template v-slot:subtitle>
      <employee-details :user-details="userDetails" />
    </template>
    <div>
      <div class="flex mb-4">
        <p class="text-jet text-sm mr-5">
          Effective Date
        </p>
        <checkbox
          v-model="suspendPayload.indefinite"
          label="indefinite Suspension"
          label-style="margin-top: -4px"
          checkbox-size="height:16px; width:16px;"
        />
      </div>
      <div v-if="!suspendPayload.indefinite" class="flex items-center gap-2 mb-4">
        <div class="date-flex w-full">
          <datepicker
            placeholder="--Start Date--"
            input-class="date-input "
            style="width:100%;"
            v-model="suspendPayload.startDate"
          />
        </div>
        <p class="text-sm whitespace-nowrap">--to--</p>
        <div class="date-flex w-full">
          <datepicker
            placeholder="--End Date--"
            input-class="date-input"
            style="width:100%; outline:none;"
            calendar-class="-ml-24"
            :disabled-dates="{ to: new Date(suspendPayload.startDate) }"
            v-model="suspendPayload.endDate"
          />
        </div>
      </div>
      <div v-else class="date-flex">
        <datepicker
          placeholder="--Start Date--"
          input-class="date-input "
          style="width:100%;"
          v-model="suspendPayload.startDate"
        />
      </div>
      <divider border-dash class="my-4" />

      <div
        class="grid grid-cols-12 gap-4 text-darkPurple"
        style="margin-top:1.125rem"
      >
        <div class="col-span-12" style="margin-right:24px">
          <combobox
            placeholder="--Type or Select--"
            label="Reason for suspension"
            :items="suspensionReasons"
            @change="isExisitingReason = true"
            variant="w-full"
            v-model="suspendPayload.suspensionReasonId"
          />
        </div>
        <div class="col-span-12 " style="margin-right:24px">
          <label class="date-label">Additional Comments</label>
          <c-textarea
            placeholder="--Select--"
            variant="w-full"
            :col="50"
            type="textarea"
            v-model="suspendPayload.comments"
          />
        </div>
      </div>

      <divider border-dash class="my-6" />

      <checkbox
        v-model="suspendPayload.payrollStatus"
        label="Deactivate employee on payroll."
        label-style="margin-top: -4px"
        checkbox-size="height:16px; width:16px; margin-top-4px"
      />
    </div>
  </RightSideBar>
</template>

<script>
import combobox from "@scelloo/cloudenly-ui/src/components/combobox";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Datepicker from "vuejs-datepicker";
import RightSideBar from "@/components/RightSideBar";
import Checkbox from "@/components/Checkbox";
import Divider from "@/components/divider";
import EmployeeDetails from "./employeeDetails";

export default {
  name: "SuspendEmployee",
  components: {
    RightSideBar,
    Checkbox,
    CTextarea,
    EmployeeDetails,
    Datepicker,
    Divider,
    combobox
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    userDetails: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      suspensionReasons: [],
      today: new Date(),
      isExisitingReason: false,
      suspendPayload: {
        startDate: null,
        endDate: null,
        indefinite: null,
        suspensionReasonId: null,
        comments: null,
        payrollStatus: null
      },
      loading: true
    };
  },
  watch: {
    openModal(value){
      if(value){
        this.$_getSuspensionReason().then(result => {
      this.suspensionReasons = result.data.reasons.map(item => ({
        value: item.reason,
        ...item
      }));
      this.loading = false;
    });
      }
    }
  },
  methods: {
    handleIndefinite() {},
    handleClose() {
      this.suspendPayload.startDate = null;
      this.suspendPayload.endDate = null;
      this.suspendPayload.indefinite = null;
      this.suspendPayload.suspensionReasonId = null;
      this.suspendPayload.comments = null;
      this.suspendPayload.payrollStatus = null;
      this.$emit("close");
    },
    handleSubmit() {
      this.suspendPayload.userId = this.userDetails.userId;
      this.suspendPayload.orgId = this.$AuthUser.orgId;
      this.suspendPayload.startDate = this.$DATEFORMAT(
        new Date(this.suspendPayload.startDate),
        "yyyy-MM-dd"
      );
      if (!this.suspendPayload.indefinite) {
        this.suspendPayload.endDate = this.$DATEFORMAT(
          new Date(this.suspendPayload.endDate),
          "yyyy-MM-dd"
        );
      }
      const result = this.suspensionReasons.find(
        item => item.id === this.suspendPayload.suspensionReasonId
      );
      if (result) {
        this.submitPayload();
      } else {
        this.$_suspensionReason({
          orgId: this.suspendPayload.orgId,
          reason: this.suspendPayload.suspensionReasonId
        }).then(({ data }) => {
          this.suspendPayload.suspensionReasonId = data.reason.id;
          this.submitPayload();
        });
      }
    },
    submitPayload() {
      this.$_employeeActions("suspend", this.suspendPayload)
        .then(() => {
          this.handleClose();
          this.$toasted.success("Employee Suspended", {
            duration: 3000
          });
        })
        .catch(() => {
          this.$toasted.error(`Employee not Suspended `, {
            duration: 3000
          });
        });
    }
  },
};
</script>

<style scoped lang="scss">
</style>
