<template>
  <div
    class="divider"
    :style="style"
  />
</template>

<script>
export default {
  props: {
    borderDash: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      const borderStyle = this.borderDash ? 'dashed' : 'solid';
      const style = `border-bottom: 1px ${borderStyle} #878E99;`;

      if (this.vertical) {
        return `${style}transform: rotate(90deg);`;
      }
      return style;
    },
  },

};
</script>

<style>
.divider{
      transform: rotate(0deg);
      -ms-transform: rotate(0deg)
  }
</style>
