<template>
  <div class="flex h-full w-full">
    <div v-if="!showError" class="w-full">
      <div class="mx-3">
        <Card class="p-5">
          <template v-slot:footer>
            <card-footer
              printcard
              reloadcard
              @print="print"
              @reload="reload()"
              @sortType="handleSort($event)"
              @searchResult="handleSearch($event)"
            />
          </template>
        </Card>
      </div>
      <div class="w-full h-full p-3 mb-8">
        <sTable
          :headers="employeeHeader"
          :items="employees"
          style="width: 100%"
          :loading="loading"
          id="printMe"
          class="w-full"
          aria-label="invited employees"
          has-checkbox
          has-number
        >
          <template v-slot:item="{ item }">
            <span
              v-if="item.activated"
              :class="getColor(item.data.activated)"
              class="
                tw-flex
                p-1
                tw-items-center tw-justify-center tw-rounded tw-text-white
              "
              >{{ item.data.activated ? "Accepted" : "Pending" }}</span
            >
            <span
              class="tw-font-bold tw-cursor-pointer"
              style="color: #525252"
              v-else-if="item.fname"
              >{{ item.data.fname }} {{ item.data.lname }}</span
            >
            <span v-else-if="item.email" class="tw-text-center">{{
              !item.data.email ? "-" : item.data.email
            }}</span>
            <span v-else-if="item.createdAt" class="tw-mr-4 tw-text-center">
              {{ $DATEFORMAT(new Date(item.data.createdAt), "dd MMMM, yyyy") }}
            </span>
          </template>
        </sTable>
      </div>
    </div>
    <div class="w-full" v-else>
      <error-page />
    </div>
    <invite-employees
      :open-modal="openModal"
      @close="$emit('close'), $store.dispatch('employee/getEmployeeInvite')"
    />
  </div>
</template>

<script>
import STable from "@scelloo/cloudenly-ui/src/components/table";
import { mapState } from "vuex";
import Card from "@/components/Card";
import ErrorPage from "@/modules/Admin/error403";
import CardFooter from "@/components/CardFooter";
import InviteEmployees from "./sideActions/inviteEmployees";

export default {
  name: "AllEmployees",
  components: {
    STable,
    ErrorPage,
    Card,
    CardFooter,
    InviteEmployees,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchWord: null,
      sortType: null,
      showError: false,
      employeeHeader: [
        { title: "NAME", value: "fname" },
        { title: "EMAIL", value: "email" },
        { title: "STATUS", value: "activated" },
        { title: "DATE ADDED", value: "createdAt" },
      ],
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.employee.loading,
      employees: (state) => state.employee.listAllInvite,
    }),
  },
  methods: {
    queryUpdate() {
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      this.$store.dispatch("employee/getEmployeeInvite", `${sort}${search}`);
    },
    handleSearch(searchWord) {
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleSort(sortType) {
      this.sortType = sortType;
      this.queryUpdate();
    },
    reload() {
      this.$store.dispatch("employee/getEmployeeInvite");
    },
    async print() {
      await this.$htmlToPaper("printMe");
    },
    getColor(value) {
      if (value) {
        return "account_active";
      }
      return "account_pending";
    },
  },
  watch: {},
  async mounted() {
    try {
      await this.$handlePrivilege(
        "employeeDirectory",
        "viewInviteEmployeesRegister"
      );
      this.$store.dispatch("employee/getEmployeeInvite");
      this.showError = false;
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.account {
  border-radius: 5px;
  width: fit-content;
  &_pending {
    background: rgba(242, 201, 76, 0.15);
    color: #e99323;
  }
  &_active {
    background: rgba(19, 181, 106, 0.15);
    color: #27ae60;
  }
}
</style>
