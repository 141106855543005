import { render, staticRenderFns } from "./promoteEmployee.vue?vue&type=template&id=0b7d65ab"
import script from "./promoteEmployee.vue?vue&type=script&lang=js"
export * from "./promoteEmployee.vue?vue&type=script&lang=js"
import style0 from "./promoteEmployee.vue?vue&type=style&index=0&id=0b7d65ab&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports