<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="openModal"
      @close="handleClose"
      :loading="loading"
      submit="Save"
      @submit="handleSubmit(submitTransfer)"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Transfer Employee</p>
      </template>
      <template v-slot:subtitle>
        <employee-details :user-details="userDetails" />
      </template>
      <div>
        <p class="text-jet text-sm">Effective Date</p>
        <div class="date-flex">
          <datepicker
            placeholder="--Select Date--"
            input-class="date-input"
            style="width: 100%; outline: none"
            v-model="effectiveDate"
            :rules="['required']"
          />
        </div>
        <divider border-dash class="my-4" />

        <div>
          <p class="mb-5">Transfer Option <span>(Select one or both):</span></p>
          <div class="flex">
            <checkbox
              v-model="locationOption"
              label="Location Based"
              @change="handleTransferOptions"
              checkbox-size="height:16px; width:16px; margin-top:4px"
            />
            <checkbox
              v-model="functionalOption"
              label="Functional"
              @change="handleTransferOptions"
              class="ml-7"
              checkbox-size="height:16px; width:16px; margin-top:4px"
            />
          </div>
        </div>

        <div
          class="grid grid-cols-12 gap-2 text-darkPurple"
          style="margin-top: 1.125rem"
        >
          <div class="col-span-12">
            <c-select
              placeholder="--Select--"
              :options="locations"
              v-if="openLocationInput"
              :rules="[openLocationInput ? 'required' : '']"
              label="New Office Location"
              variant="w-full"
              v-model="officeLocation"
            />
          </div>
          <div class="col-span-12">
            <c-select
              placeholder="--Select--"
              label="New Function"
              v-if="openFunctionInput"
              :rules="[openFunctionInput ? 'required' : '']"
              variant="w-full"
              :options="functions"
              v-model="department"
            />
          </div>
        </div>

        <divider border-dash class="my-6" />

        <div
          style="
            background: #ffffff;
            border: 1px solid #878e99;
            box-sizing: border-box;
            border-radius: 5px;
          "
          class="p-3"
        >
          <checkbox
            v-model="locationBase"
            @change="handleEligibility"
            label="Transfer eligible for payroll review"
            checkbox-size="height:16px; width:16px; margin-top: 4px;"
          />
          <div v-if="showEligibilityOptions">
            <divider class="my-4" />
            <radio-button
              :options="eligibleArray"
              col-span="col-span-6"
              class="inline-block text-darkPurple"
              @change="handleEligibilityChange"
              v-model="increaseBy"
            />
            <div class="grid grid-cols-12 gap-6">
              <div class="col-span-6">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full h-10"
                  :disabled="disablePercentInput"
                  v-model="percentRise"
                />
              </div>
              <div class="col-span-6">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full h-10"
                  :disabled="disableAmountInput"
                  v-model="amountRise"
                />
              </div>
              <div class="col-span-12">
                <div class="text-sm mb-2 text-jet">New Gross Annual Salary</div>
                <div class="payInfo payInfo_fakeInput text-center">
                  {{ newSalary }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Datepicker from "vuejs-datepicker";
import RightSideBar from "@/components/RightSideBar";
import Checkbox from "@/components/Checkbox";
import Divider from "@/components/divider";
import RadioButton from "@/components/RadioButton";
import EmployeeDetails from "./employeeDetails";

export default {
  name: "TransferEmployee",
  components: {
    RightSideBar,
    ValidationObserver,
    Checkbox,
    CText,
    EmployeeDetails,
    Datepicker,
    RadioButton,
    Divider,
    CSelect,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    userDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      locations: [],
      locationBase: false,
      functionalOption: false,
      locationOption: true,
      officeLocation: null,
      department: null,
      loading: true,
      openLocationInput: true,
      openFunctionInput: false,
      effectiveDate: null,
      increaseBy: null,
      percentRise: null,
      showEligibilityOptions: false,
      disablePercentInput: true,
      disableAmountInput: true,
      amountRise: null,
      payrollReview: null,
      eligibleArray: [
        {
          name: "Increase by percentage(%)",
          radioName: "eligible",
          value: true,
        },
        { name: "Increase by amount(₦) ", radioName: "eligible", value: false },
      ],
    };
  },
  computed: {
    newSalary() {
      let result = this.userDetails.grossPay;
      const percentage = this.percentRise;
      const amount = this.amountRise;
      if (percentage) {
        const per = Number(percentage);
        const percent = per / 100;
        result =
          percent * this.userDetails.grossPay + this.userDetails.grossPay;
      }
      if (amount) {
        const amt = Number(amount);
        result = amt + this.userDetails.grossPay;
      }
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    functions() {
      return this.$store.state.org.getAllFunctions;
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    handleTransferOptions() {
      if (this.locationOption) {
        this.openLocationInput = true;
      } else {
        this.openLocationInput = false;
      }
      if (this.functionalOption) {
        this.openFunctionInput = true;
      } else {
        this.openFunctionInput = false;
      }
    },
    handleEligibility() {
      if (this.locationBase) {
        this.showEligibilityOptions = true;
      } else {
        this.showEligibilityOptions = false;
      }
    },
    handleClose() {
      this.$emit("close");
      this.locationBase = false;
      this.functionalOption = false;
      this.locationOption = true;
      this.officeLocation = null;
      this.effectiveDate = null;
      this.department = null;
      this.amountRise = null;
      this.percentRise = null;
      this.disablePercentInput = true;
      this.disableAmountInput = true;
      this.showEligibilityOptions = false;
      this.openFunctionInput = false;
    },
    // TODO: fix the inputField size when disabled
    handleEligibilityChange(strValue) {
      const value = strValue ? strValue === "true" : strValue === "false";
      this.payrollReview = value;
      if (value) {
        this.disableAmountInput = true;
        this.disablePercentInput = false;
      } else {
        this.disablePercentInput = true;
        this.disableAmountInput = false;
      }
    },
    checkOption() {
      if (this.locationOption && this.functionalOption) {
        return "both";
      }
      if (this.locationOption) return "location";
      if (this.functionalOption) return "functional";
      return null;
    },
    submitTransfer() {
      const payload = {
        effectiveDate: this.$DATEFORMAT(
          new Date(this.effectiveDate),
          "yyyy-MM-dd"
        ),
        userId: this.userDetails.userId,
        transferOption: this.checkOption(),
        orgId: this.$AuthUser.orgId,
        payrollReview: this.showEligibilityOptions,
        percentRise: this.percentRise,
        amountRise: this.amountRise,
        officeAddress: this.officeLocation,
        functionId: this.department,
      };
      this.$_employeeActions("transfer", payload)
        .then(() => {
          this.$toasted.success("Employee transfered successfully", {
            duration: 5000,
          });
          this.handleClose();
          this.$emit("submitted");
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
  },
  watch: {
    openModal(value) {
      if (value) {
        this.$store.dispatch("org/getFunction");
        this.$_getHumanarLocations().then((result) => {
          this.locations = result.data.outlets;
          this.loading = false;
        });
      }
    },
  },
};
</script>

<style lang="scss">
.payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
    padding: 10px 15px;
    max-height: 40px;
  }
}
</style>
