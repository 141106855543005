<template>
  <div class="flex h-full w-full">
    <div v-if="!showError" class="w-full">
      <div class="mx-3">
        <Card class="p-5">
          <template v-slot:footer>
            <card-footer
              printcard
              reloadcard
              @reload="onGetQuery"
              @sortType="onGetQuery({ sort: $event })"
              @searchResult="onGetQuery({ search: $event })"
              @print="$htmlToPaper('printMe')"
            />
          </template>
        </Card>
      </div>
      <div style="height:100%" v-if="isLoading">
        <loader size="xxs" :loader-image="false" />
      </div>
      <template v-else>
        <template v-if="employees.length">
          <div class="w-full h-full p-3 mb-8">
            <sTable
              :headers="headers"
              :loading="isTableLoading"
              :items="employees"
              id="printMe"
              aria-label="disengaged employee table"
              :pagination-list="pagination"
              @page="onGetQuery({ page: $event })"
              @itemsPerPage="onGetQuery({ perPage: $event })"
              page-sync
            >
              <template v-slot:item="{ item }">
                <template v-if="item.userInfo">
                  <div class="flex flex-row items-center whitespace-nowrap">
                    <template>
                      <div class="flex w-2 h-2 rounded mr-2">
                        <span class="p-1 rounded"
                          :class="item.data.userInfo.employmentStatus === 'confirmed' ? 'bg-mediumSeaGreen':
                          item.data.userInfo.employmentStatus === 'suspended' ? 'bg-carrotOrange':
                          item.data.userInfo.employmentStatus === 'disengaged' ? 'bg-flame' :
                          item.data.userInfo.employmentStatus === 're-engaged' ? 'bg-mediumSeaGreen' : 'bg-romanSilver'"
                        />
                      </div>
                    </template>
                    <template>
                      <img v-if="item.data.photo" :src="item.data.photo" alt="profile pic" class="w-8 h-8 rounded" />
                      <div v-else class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2">
                        {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                      </div>
                    </template>
                    <div class="ml-2 text-sm flex flex-col justify-Start">
                      <span class="font-bold cursor-pointer" style="color:#525252;">
                        {{ item.data.fname }} {{ item.data.lname }}
                      </span>
                      <div class="text-romanSilver text-xs uppercase font-bold">
                        <span v-if="item.data.userDesignation">
                          {{ item.data.userDesignation.name }}
                        </span>
                        <span v-else>-</span>
                      </div>
                    </div>
                  </div>
                </template>
                <span v-if="item.employmentType" class="text-jet capitalize">
                  {{ item.data.employmentType }}
                </span>
                <div v-if="item.orgFunction" class="text-jet capitalize">
                  <span v-if="item.data.orgFunction">
                    {{ item.data.orgFunction.name }}
                  </span>
                  <span v-else>-</span>
                </div>
                <div v-if="item.userDesignation" class="text-jet capitalize">
                  <span v-if="item.data.userDesignation">
                    {{ item.data.userDesignation.name }}
                  </span>
                  <span v-else>-</span>
                </div>
                <span v-if="item.userReportingTo" class="text-jet capitalize">
                  <span v-if="item.data.userReportingTo">
                    {{ item.data.userReportingTo.fname }} {{ item.data.userReportingTo.lname }}
                  </span>
                  <span v-else>-</span>
                </span>
                <span v-if="item.suspend" class="text-jet capitalize">
                  {{ item.data.suspend.reason }}
                </span>
                <div v-if="item.effectiveDate" class="flex flex-col justify-start">
                  <span class="text-jet" v-if="item.data.effectiveDate">
                    {{ $DATEFORMAT(new Date(item.data.effectiveDate), "MMM dd, yyyy") }}
                  </span>
                </div>
                <div v-if="item.startDate" class="flex flex-col justify-start">
                  <span class="text-jet" v-if="item.data.startDate">
                    {{ $DATEFORMAT(new Date(item.data.startDate), "MMM dd, yyyy") }}
                  </span>
                  <span class="text-jet" v-if="item.data.endDate">
                    <small class="text-flame font-bold">to</small>
                    {{ $DATEFORMAT(new Date(item.data.endDate), "MMM dd, yyyy") }}
                  </span>
                </div>
                <div v-if="item.payrollStatus" class="text-center capitalize">
                  <span :class="{approved: item.data.payrollStatus, disapproved: !item.data.payrollStatus}">
                    {{ item.data.payrollStatus ? 'Active' : 'Inactive' }}
                  </span>
                </div>
                <div v-if="item.approvalStatus">
                  <span v-if="item.data.approvalStatus" class="text-center capitalize"
                    :class="{
                      approved: item.data.approvalStatus === 'approved',
                      disapproved: item.approvalStatus === 'disapproved',
                      disapproved: item.data.approvalStatus === 'cancelled',
                      disapproved: item.data.approvalStatus === 'terminated',
                      pending: item.data.approvalStatus === 'pending',
                    }"
                  > {{ item.data.approvalStatus }}
                  </span>
                </div>
                <span v-if="item.id">
                  <Menu left top="-150" margin="24" class="my-2 p-0">
                    <template v-slot:title>
                      <Icon icon-name="more_icon" size="xs" />
                    </template>
                    <div class="w-40 px-3 py-2 flex flex-col justify-start gap-2">
                      <div @click="onShowEmployee(item.data)">
                        <div class="flex flex-row justify-start items-center gap-2 cursor-pointer">
                          <Icon icon-name="edit" class-name="text-yellow" size="xs" />
                          <p class="text-sm">View</p>
                        </div>
                      </div>
                      <div
                    @click="
                      viewPaySlip(item.data.disengaged.userId)
                    "
                  >
                    <div
                      class="
                        flex flex-row
                        justify-start
                        items-center
                        gap-2
                        cursor-pointer
                      "
                    >
                      <Icon
                        icon-name="icon-eye"
                        class-name="text-yellow"
                        size="xs"
                      />
                      <p class="text-sm">View Payslip</p>
                    </div>
                  </div>
                      <template v-if="item.data.disengageStat">
                    <div
                      @click="handleRengage(item.data)"
                      v-if="item.data.disengageStat.status === 'approved'"
                    >
                      <div
                        class="
                          flex flex-row
                          justify-start
                          items-center
                          gap-2
                          cursor-pointer
                        "
                      >
                        <Icon
                          icon-name="icon-terminate"
                          class-name="text-yellow"
                          size="xms"
                        />
                        <p class="pt-1 text-sm">Re-engage</p>
                      </div>
                    </div>
                  </template>
                      <div @click="$refs.approval_status.toggle(item.data.id)">
                        <div class="flex flex-row justify-start items-center gap-2 cursor-pointer">
                          <Icon icon-name="icon-check-square" class-name="text-mediumSeaGreen" size="xs" />
                          <p class="pt-1 text-sm">Approval Status</p>
                        </div>
                      </div>
                    </div>
                  </Menu>
                </span>
              </template>
            </sTable>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col justify-center items-center gap-5 px-10 py-10">
            <Icon size="" class-name="w-80 h-80" icon-name="payinsight_Illustration" />
            <p class="font-normal text-base leading-5 text-jet text-center w-3/6">
              A list of Disengaged Employees for your organisaton will be shown here. Once added you can filter and perform necessary actions
            </p>
          </div>
        </template>
      </template>
    </div>
    <div class="w-full" v-else>
      <error-page />
    </div>
    <template>
      <ApprovalStatus ref="approval_status" />
      <ReEngageEmployee ref="reengage" @success="onGetQuery" />
    </template>
    <template>
      <Modal v-if="isDelete">
        <Card class="p-5 flex flex-col justify-center max-w-md">
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Employee?
          </p>
          <div class="flex flex-row justify-center gap-5">
            <Button
              label="Yes"
              variant="primary btn-bg"
              class="button_class"
              @onClick="onDelete(userId)"
            />
            <Button
              label="No"
              variant="secondary"
              class="button_class"
              @onClick="isDelete = false"
            />
          </div>
        </Card>
      </Modal>
    </template>
  </div>
</template>

<script>
import STable from "@scelloo/cloudenly-ui/src/components/table";
import * as _ from "lodash";
import Button from "@scelloo/cloudenly-ui/src/components/button";
import Card from "@/components/Card";
import ErrorPage from "@/modules/Admin/error403";
import CardFooter from "@/components/CardFooter";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";

export default {
  name: "DisengagedEmployees",
  components: {
    STable,
    Menu,
    Card,
    ErrorPage,
    Modal,
    Button,
    CardFooter,
    ApprovalStatus: () => import("@/components/ApprovalStatus"),
    ReEngageEmployee: () => import("./sideActions/reEngageEmployee")
  },
  data() {
    return {
      isDelete: false,
      isLoading: true,
      showError: false,
      isTableLoading: true,
      userId: '',
      pagination: {},
      employees: [],
      headers: [
        { title: "Employee", value: "userInfo" },
        { title: "Employment Type", value: "employmentType" },
        { title: "Function", value: "orgFunction" },
        { title: "Designation", value: "userDesignation" },
        { title: "Reporting To", value: "userReportingTo" },
        { title: "Effective Date", value: "effectiveDate", width: 14 },
        { title: "STATUS", value: "approvalStatus" },
        { title: "", value: "id", image: true }
      ]
    };
  },
  methods: {
    async viewPaySlip(userId) {
      try {
        await this.$handlePrivilege("employeeDirectory", "viewPayslip");
        this.$router.push({ name: "viewEmployee", params: { id: userId } });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async handleRengage(data) {
      try {
        await this.$handlePrivilege("employeeDirectory", "re-engageEmployees");
        this.$refs.reengage.toggle(data);
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
   async onShowEmployee(employee) {
      try {
        await this.$handlePrivilege("employeeDirectory", "viewEmployeeDetails");
        this.$router.push({
          name: "EditPersonalInfo",
          params: { id: employee.disengaged.userId },
          query: {
            currentTab: "Personal Info",
            user: `${employee.fname} ${employee.lname}`,
          },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    onDelete(userId) {
      this.$_deleteEmployee(userId)
        .then(({ data }) => {
          this.isDelete = false;
          this.getOrgDisengagedEmployees();
          this.$toasted.success(data.message, { duration: 3000 });
        })
        .catch((error) => {
          this.isDelete = false;
          this.getOrgDisengagedEmployees();
          this.$toasted.error(error.message, { duration: 3000 });
        });
    },
    onGetQuery(queryParams) {
      if (queryParams !== undefined) {
        this.queryParams = { ...this.queryParams, ...queryParams };
        this.getOrgDisengagedEmployees(this.queryParams);
      } else {
        this.queryParams = {};
        this.getOrgDisengagedEmployees();
      }
    },
    async getOrgDisengagedEmployees(params){
      this.isTableLoading = true
      await this.$_getOrgDisengagedEmployees(params).then(({ data }) => {
        this.employees = data.data?.employees.map((employee) => {
          const activeApprovalStatus = _.size(_.keys(employee.reengageStat)) ?
            employee.reengageStat?.approvalStatus : employee.disengageStat?.status
          const activeApprovalId = _.size(_.keys(employee.reengageStat)) ?
            employee.reengageStat?.id : employee.disengageStat?.id

          return {
            ...employee,
            ...employee.userInfo,
            ...employee.disengaged,
            approvalStatus: activeApprovalStatus,
            id: activeApprovalId
          }
        })

        this.pagination = data.data.meta
        this.isTableLoading = false
      })

    }
  },
  async created() {
    try {
      await this.$handlePrivilege(
        "employeeDirectory",
        "viewDisengageEmployeesRegister"
      );
      this.showError = false;
      this.isLoading = true;
      await this.getOrgDisengagedEmployees();
      this.isLoading = false;
    } catch (error) {
      this.showError = true;
      }
   }
};
</script>

<style lang="scss" scoped>
.account {
  border-radius: 5px;
  width: fit-content;
  &_pending {
    background: rgba(233, 147, 35, 0.15);
    color: #e99323;
  }
  &_suspended {
    background: rgba(234, 60, 83, 0.15);
    color: #ea3c53;
  }
}
</style>
<style scoped>
.pending {
  background: rgba(233, 147, 35, 0.08);
  color: #e99323;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.approved {
  background: rgba(19, 181, 106, 0.08);
  color: #13b56a;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.disapproved {
  background: rgba(234, 60, 83, 0.08);
  color: #ea3c53;
  text-align: center;
  padding: 8px;
  border-radius: 4px;
}
.button_class {
  padding: 10px 70px !important;
  font-weight: 600;
  display: flex;
  justify-self: end;
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
</style>
