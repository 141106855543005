<template>
  <div
    class="w-full h-full flex flex-col mt-12 mb-8"
    style="align-items: center"
  >
    <div class="w-5/12 h-auto mt-5 mb-5 flex" style="justify-content: center">
      <icon icon-name="error-403" class-name="icon-Size mt-4 text-white" />
    </div>
    <div
      class="w-6/12 h-auto text-center"
      style="width: 610px; line-height:19px"
    >
      <p class="text-base text-jet ">
        The data you are trying to access has restrictions.
      </p>
      <p class="text-flame text-sm">Please contact your administrator</p>
      <!-- <div
        class="text-flame flex w-full cursor-pointer"
        style="justify-content: center"
        @click="$router.push({ name: 'AdminDashboard' })"
      >
        <icon icon-name="icon-arrow-left" class-name="text-flame" />
        <p class="text-base font-semibold ml-2">
          Go Back To Home
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";

export default {
  name: "Error403",
  components: {
    Icon
  }
};
</script>

<style scoped>
.icon-Size {
  width: 280px;
  height: 280px;
}
</style>
