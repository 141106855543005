<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="openModal"
      submit="Send Invite"
      @submit="handleSubmit(inviteEmployee)"
      @close="handleClose"
      button-class="bg-dynamicBackBtn text-white"
      :disabled-button="disableBtn"
    >
      <template v-slot:title>
        <p class="text-darkPurple">Invite Employees</p>
      </template>
      <template v-slot:subtitle>
        <p class=" font-semibold mb-3">
          Send email invites to employees.
        </p>
        <p v-feature-request>
          You can also upload a file with these details using the "<span
            class=" text-flame"
            >Import</span
          >" option.
        </p>
      <div class="flex flex-col flex-grow">
        <ValidationObserver rules="required" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(inviteEmployee)">
            <div
              v-for="(form, index) in forms"
              :key="index"
              class="flex flex-col mb-5"
              :style="
                index > 0 ? 'border: 1px dashed #878E99; padding: 12px' : ''
              "
            >
              <div
                @click="removeForm(index)"
                class=" ml-12 cursor-pointer"
                v-if="index > 0"
                style="align-self: flex-end;"
              >
                <Icon size="s" class="text-flame" icon-name="close" />
              </div>
              <div
                class="grid grid-cols-12 gap-2 text-darkPurple"
                style="margin-top:1.125rem"
              >
                <div class="col-span-6">
                  <c-text
                    placeholder="--Enter--"
                    variant="w-full h-10"
                    label="First Name"
                    :rules="['required']"
                    v-model="form.fname"
                  />
                </div>
                <div class="col-span-6">
                  <c-text
                    placeholder="--Enter--"
                    variant="w-full h-10"
                    label="Last Name"
                    :rules="['required']"
                    v-model="form.lname"
                  />
                </div>
                <div class="col-span-12">
                  <c-text
                    placeholder="--Enter--"
                    variant="w-full h-10"
                    label="Email"
                    @change="setInput = true"
                    :rules="EmailRule(index)"
                    v-model="form.emailAddress"
                  />
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
      </template>
      <div>
        <button class="flex ml-6 mb-4" @click="handleSubmit(addForm)">
          <Icon size="s" icon-name="icon-plus" />
          <p class="ml-3 text-flame font-semibold">
            Add Invite
          </p>
        </button>
      </div>


      <template v-slot:progress v-if="progressDisplay">
      <div class="range flex w-full mb-3">
        <div class="flex flex-grow py-3">
        <input type="range" :min="0" :max="100" :value="progressCount" id="range2" />
        </div>
        <div class="value2 w-1/12 ml-1 flex p-1 font-semibold">{{ Math.floor(progressCount) }}%</div>
      </div>
      </template>

      <template v-slot:footer>
        <button
          class="footerContainer mr-5 cursor-pointer text-dynamicBackBtn"
          style="width:7.875rem"
          @click="openImportModal = true"
          :disabled="disableBtn"
        >
          <p class="text-center">
            Import
          </p>
        </button>
      </template>
      <import-employee
        @close="$event === 'cancel' ? openImportModal = false : handleClose()"
        :open-modal="openImportModal"
      />
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import RightSideBar from "@/components/RightSideBar";
import ImportEmployee from "./importEmployee";

export default {
  components: {
    CText,
    RightSideBar,
    ValidationObserver,
    ImportEmployee
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      forms: [],
      disableBtn: false,
      invalidForm: false,
      openImportModal: false,
      progressCount: 0,
      progressDisplay: false,
      progressSlider: null,
      timerId: '',
      setInput: false
    };
  },
  computed:{
    EmailRule() {
      return index => [
      "required",
      {
        name: "email",
        rule: v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      },
        {
          name: 'isvalidated',
          rule: v => !v || this.validateEmailAddress(v, index) || 'The email address is already in use'
        },
      {
        name: "available",
        rule: v => this.handleEmail(v)
      },
    ]},
  },
  watch: {
    openModal() {
      if (this.openModal) {
        if (!this.forms.length) {
          this.addForm();
        }
      }
    }
  },
  methods: {
    validateEmailAddress(email, index) {
      if (this.setInput) {
        for (let i = 0; i < index; i++) {
          if (this.forms[i].emailAddress === email) {
            return false;
          }
        }
      }
    return true;
  },
    handleClose() {
      this.openImportModal = false
      this.forms = []
      this.disableBtn = false
      this.$emit('close')
    },
    async handleEmail(value) {
      const result = await this.$_checkEmailExist(value);
      if (result.data.emailExists) {
        return "Email already exists";
      }
      return true;
    },

    async inviteEmployee() {
      if (this.forms.length <= 20) {
        this.disableBtn = true;
        this.progressDisplay = true;
        const inviteUsers = {
          employees: []
        };
        this.forms.forEach(item => {
          inviteUsers.employees.push({
            email: item.emailAddress,
            fname: item.fname,
            lname: item.lname,
            slug: this.$OrgData.accountId
          });
        });
        inviteUsers.creator = this.$AuthUser.id;
        inviteUsers.orgName = this.$OrgData.name;

        this.$_inviteEmployee(inviteUsers)
          .then((response) => {
            if (this.progressDisplay) {
              this.progressBar(response.data.progressId);
            }
          })
          .catch((err) => {
            this.progressDisplay = false;
            this.$toasted.error(`${err}`, {
              duration: 3000
            });
            this.disableBtn = false;
          });
      }
      else {
               this.$toasted.error("Cannot invite more than 20 employees", {
                 duration: 3000
               });
      }
    },

    progressBar(id) {
      const sliderEl = document.getElementById("range2");

      this.$_getProgressBar(id).then((response) => {
            this.progressCount = response.data.progressStats.progress;

            sliderEl.style.background = `linear-gradient(to right, #13B56A ${response.data.progressStats.progress}%, #ccc ${response.data.progressStats.progress}%)`;

           if (response.data.progressStats.progress === 100) {
             setTimeout(() => {
               this.progressDisplay = false;
               this.$toasted.success("Employee invited successfully", {
                 duration: 3000
               });
               this.disableBtn = false;
               this.forms = [];
               this.addForm();
               this.$emit('reload')
               this.$emit("close");
               clearTimeout(this.timerId)
             }, 500);
        }

      }).catch((err) => err)

      this.timerId = setTimeout(() => { this.progressBar(id) }, 5000);
    },

    addForm() {
      this.forms.push({
        emailAddress: "",
        fname: "",
        lname: ""
      });
    },
    removeForm(index) {
      if (index > 0) this.forms.splice(index, 1); this.setInput = false;
    }
  },
  mounted() {
    this.addForm();
  }
};
</script>

<style scoped>
.footerContainer {
  border: 1px solid var(--dynamic-Background) !important;
  box-sizing: border-box;
  border-radius: 5px;
  min-height: 44px;
  padding: 8px;
}

#range2 {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 6px;
  background: #ccc;
}

#range2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #13B56A;
  border-radius: 50%;
  border: none;
  transition: .2s ease-in-out;
}

#range2::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #13B56A;
  border-radius: 50%;
  border: none;
  transition: .2s ease-in-out;
}

</style>
