<template>
  <div
    :style="`max-height:${height}`"
    class="scrollContainer scroll"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: 'calc(100vh - 400px)',
    },
  },

};
</script>

<style scoped>
.scrollContainer {
    position: relative;
    overflow-y: auto;
     }
.scroll::-webkit-scrollbar {
  width: 0.4em;
  margin-left: 1rem;
}

.scroll::-webkit-scrollbar-track {

  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.scroll::-webkit-scrollbar-thumb {
  widows: 0.3px;
  background-color: darkgrey;
  outline: 0.3px solid slategrey;
}
</style>
