<template>
    <validation-observer rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      :loading="isLoading"
      @submit="handleSubmit(onSubmit)"
      submit="Submit"
      @close="onClose"
      :disabled-button="isDisabled || isLoading"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">
          Disengage Employee
        </p>
      </template>
      <template v-slot:subtitle>
        <div class="flex flex-col justify-start pb-5">
          <p class="font-semibold text-sm text-darkPurple mb-2">Employee Details</p>
          <div class="flex justify-start items-start gap-5">
            <div class="w-44 h-32 rounded-md text-blueCrayola font-normal">
                <img class="w-44 h-32 rounded-md" v-if="employeeInfo.photo" :src="employeeInfo.photo" />
                <div v-else class="h-32 border rounded-md flex justify-center items-center">
                <span style="font-size:60px">
                  {{ $getInitials(`${employeeInfo.fname || ''} ${employeeInfo.lname || ''}`) }}
                </span>
                </div>
              </div>
            <div class="flex flex-col justify-start items-start gap-2 w-full">
              <div class="w-full flex flex-col justify-start items-start gap-2">
                <h4 class="font-bold text-lg leading-5 text-darkPurple">
                  {{ employeeInfo.fname }} {{ employeeInfo.lname }}
                </h4>
                <div class="flex flex-col justify-start items-center w-full gap-0">
                  <div class="flex justify-start items-start w-full">
                    <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                      Function:
                    </p>
                    <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.orgFunction">
                      {{ employeeInfo.orgFunction.name }}
                    </span>
                    <span v-else>-</span>
                    </p>
                  </div>
                  <div class="flex justify-start items-start w-full">
                    <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                      Designation:
                    </p>
                    <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.userDesignation">
                        {{ employeeInfo.userDesignation.name }}
                    </span>
                    <span v-else>-</span>
                    </p>
                  </div>
                  <div class="flex justify-start items-start w-full">
                    <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                      Job Level:
                    </p>
                    <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                      <span v-if="employeeInfo.userLevel">
                        {{ employeeInfo.userLevel.name }}
                      </span>
                      <span v-else>-</span>
                    </p>
                  </div>
                  <div class="flex justify-start items-start w-full">
                    <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                      Office Location:
                    </p>
                    <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.employeeOffice">
                        {{ employeeInfo.employeeOffice.name }}
                    </span>
                    <span v-else>-</span>
                    </p>
                  </div>
                  <div class="flex justify-start items-start w-full">
                    <p class="w-4/12 font-black _text-10 uppercase leading-5 text-romanSilver">
                      Reporting to
                    </p>
                    <p class="w-8/12 font-semibold text-sm leading-5 text-darkPurple">
                    <span v-if="employeeInfo.userReportingTo">
                        {{ employeeInfo.userReportingTo.fname }} {{ employeeInfo.userReportingTo.lname }}
                    </span>
                    <span v-else>-</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:default>
        <div class="flex flex-col justify-start">
          <div class="w-full flex flex-col justify-start gap-2">
            <p class="text-jet text-sm mr-5">Effective Date</p>
            <div :class="'date-flex'">
              <datepicker
                placeholder="--Start Date--"
                input-class="date-input"
                style="width:100%;"
                v-model="payload.effectiveDate"
              />
            </div>
          </div>
          <div class="flex flex-col justify-start gap-5 pt-4">
            <div class="text-sm text-jet leading-5">
              <combobox
                placeholder="--Type or Select--"
                label="Reason for Disengagement"
                :items="reasonsOptions"
                v-model="payload.reason"
                variant="w-full"
              />
            </div>
            <div class="">
              <label name="comment" class="date-label">
                Comment
              </label>
              <c-textarea
                placeholder="--Select--"
                variant="w-full"
                :col="50"
                type="textarea"
                name="comment"
                v-model="payload.comment"
              />
            </div>
          </div>
        </div>
      </template>
    </RightSideBar>
    </validation-observer>
</template>

<script>
import * as _ from 'lodash'
import { format } from 'date-fns'
import Datepicker from "vuejs-datepicker"
import { ValidationObserver } from "vee-validate"
import Combobox from "@scelloo/cloudenly-ui/src/components/combobox";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import RightSideBar from "@/components/RightSideBar";

export default {
  components: {
    ValidationObserver,
    Datepicker,
    RightSideBar,
    CTextarea,
    Combobox
  },
  data() {
    return {
      isLoading: true,
      isOpen: false,
      isOpenAttachment: false,
      reasonsOptions: [],
      employeeInfo: {},
      isDisabled: true,
      payload: {
        userId: '',
        reason: null,
        comment: '',
        effectiveDate: '',
      },
    }
  },
  watch: {
    payload: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value.effectiveDate && value.reason) {
          this.isDisabled = false
        } else this.isDisabled = true
      }
    }
  },
  methods: {
    toggle(payload){
      this.employeeInfo = payload
      this.isOpen = true
    },
    onClose(){
      this.isOpen = false
      this.isLoading = false
      _.assign(this.$data.payload, this.$options.data().payload)
    },
    async onSubmit(){
      this.isLoading = true
      const findReasonIndex = _.find(this.reasonsOptions,
        reason => reason.id === this.payload.reason
      )
      if(!_.size(_.keys(findReasonIndex))){
        await this.$_suspensionReason({orgId: this.$orgId, reason: this.payload.reason})
        .then(({ data }) => {this.payload.reason = data.reason.id})
        .catch((error) => this.$toasted.error(error.message, {duration: 3000}))
      }

      const payload = {
        extendBy: 1,
        orgId: this.$orgId,
        reasonId: this.payload.reason,
        comments: this.payload.comment,
        confirmUserId: this.employeeInfo.userId,
        additionalComment: this.payload.comment,
        payrollStatus: this.employeeInfo.payrollStatus === 'active',
        effectiveDate: format(this.payload.effectiveDate, "yyyy-MM-dd"),
        initiatedBy: this.$AuthUser.id
      }

      await this.$_disengageOrgEmployee(payload).then(({ data }) => {
        const message = "Employee disengage action has been sent for Approval"
        this.$toasted.success(message, {duration: 3000})
        this.$emit('success', data)
        this.onClose()
      }).catch((error) => {
        this.$toasted.error(error, {duration: 3000})
        this.payload = {}
        this.isLoading = false
      })

    },
    async getSuspensionReason(){
      await this.$_getSuspensionReason().then(({ data }) => {
        this.reasonsOptions = data.reasons?.map(reason => ({
          ...reason, value: reason.reason
        }))
      })
    },
  },
  async mounted() {
    this.isLoading = true
    await this.getSuspensionReason()
    this.isLoading = false
  }
};
</script>

<style scoped>
._font-10 {
  font-size: 10px !important;
}
._text-10 {
  font-size: 10px !important;
}
</style>
<style>
.rightSidebar {
  width: 520px !important;
}
</style>
