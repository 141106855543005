<template>
  <div class="w-full h-full pt-5 px-3">
    <h1 class="text-xl text-left font-extrabold pl-4">Employees</h1>
    <card class=" mt-3">
      <div class="">
        <Tab
          :tabs="tabOptions"
          border
          :active-tab="currentTab"
          @currentTab="currentTab = $event"
        />
      </div>

      <div class="w-full flex pt-5 px-5 pb-1">
        <div class="flex flex-col flex-grow">
          <div class="flex p-1" v-if="currentTab === 'All Employees'">
            <span class="text-sm font-semibold">
              Employees Subscription Usage
            </span>
          </div>
          <div class="mt-2 flex" v-if="currentTab === 'All Employees'">
            <svg width="60" height="60" viewBox="0 0 42 42" class="donut">
              <circle
                class="donut-hole"
                cx="21"
                cy="21"
                r="15.91549430918954"
                fill="#fff"
              ></circle>
              <circle
                class="donut-ring"
                cx="21"
                cy="21"
                r="15.91549430918954"
                fill="transparent"
                stroke-width="8"
              ></circle>
              <circle
                class="donut-segment"
                cx="21"
                cy="21"
                r="15.91549430918954"
                fill="transparent"
                stroke="#FE4D3C"
                stroke-width="8"
                :stroke-dasharray="`${varAllocated} ${varUnAllocated}`"
                stroke-dashoffset="25"
                v-tooltip.right="allocatedTooltip"
              ></circle>
              <circle
                class="donut-segment"
                cx="21"
                cy="21"
                r="15.91549430918954"
                fill="transparent"
                stroke="#EEEEEE"
                stroke-width="8"
                :stroke-dasharray="`${varUnAllocated} ${varAllocated}`"
                :stroke-dashoffset="strokeOffset"
                v-tooltip.bottom="unAllocatedTooltip"
              ></circle>
            </svg>

            <span class="flex ml-2 pt-1">
              <span
                class="font-black -mt-3"
                style="color: #fe4d3c; font-size: 48px"
              >
                {{ allocatedUsers }}
              </span>
              <span
                class="
                  text-romanSilver
                  font-semibold
                  text-base
                  ml-1
                "
                style="line-height: 4.3"
              >
                of {{ totalUsers }}
              </span>
            </span>
          </div>
        </div>
        <div
          class="flex mr-3 mt-2"
          v-if="currentTab === 'All Employees'"
          style="justify-content: flex-end"
        >
          <Button
            class="mt-5 text-white bg-dynamicBackBtn"
            style="height: 2.75rem"
            v-if="$store.state.subscription && $store.state.subscription.plan === 'basic'"
            @click="checkEmployee('Create New')"
          >
            Create New
          </Button>
          <Button
            class="mt-5 text-white bg-dynamicBackBtn"
            style="height: 2.75rem"
            options
            v-else
            :option-list="['From Existing User/People', 'Create New']"
            @click="checkEmployee($event)"
          >
            New Employee
          </Button>
          <Button
            background-color="none"
            class="text-dynamicBackBtn border border-solid border-dynamicBackBtn mt-5 ml-4"
            style="height: 2.75rem"
            @click="handleImport"
          >
            Import from File
          </Button>
        </div>
        <div
          class="flex"
          v-if="currentTab === 'Invited Employees'"
          style="justify-content: flex-end"
        >
          <Button class="text-white bg-dynamicBackBtn" @click="checkEmployee"
          >
            Invite Employee
          </Button>
        </div>
      </div>

      <all-employees
        v-if="currentTab === 'All Employees'"
        :open-employee="openEmployeeFromPeople"
        @close="openEmployeeFromPeople = false"
        @reload="subscriptionData()"
        :open-import-employee="openImport"
        @closeImport="openImport = false"
      />
      <invited-employees
        v-if="currentTab === 'Invited Employees'"
        :open-modal="openInvitedEmployee"
        @close="openInvitedEmployee = false"
      />
      <suspended-employees v-if="currentTab === 'Suspended Employees'" />
      <disengaged-employees v-if="currentTab === 'Disengaged Employees'" />
    </card>
  </div>
</template>

<script>
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Card from "@/components/Card";
import Button from "@/components/Button";
import AllEmployees from "./allEmployees";
import InvitedEmployees from "./invitedEmployee";
import SuspendedEmployees from "./suspendedEmployees";
import DisengagedEmployees from "./disengagedEmployees";

export default {
  components: {
    Tab,
    Card,
    Button,
    AllEmployees,
    InvitedEmployees,
    SuspendedEmployees,
    DisengagedEmployees,
  },
  data() {
    return {
      currentTab: this.$route.query.status || "All Employees",
      loading: null,
      allocatedUsers: "",
      totalUsers: "",
      tabOptions: [
        "All Employees",
        "Invited Employees",
        "Suspended Employees",
        "Disengaged Employees",
      ],
      openEmployeeFromPeople: false,
      openImport: false,
      openInvitedEmployee: false,
      chartOptions: {
        legend: {
          display: false,
          position: "bottom",
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      chartLabels: [],
      varAllocated: "",
      varUnAllocated: "",
      strokeOffset: 0,
      allocatedTooltip: "",
      unAllocatedTooltip: "",
    };
  },

  watch:{
    currentTab(){
      this.subscriptionData()
    }
  },
  methods: {
    async handleImport() {
      try {
        await this.$handlePrivilege("employeeDirectory", "importEmployees");
        this.openImport = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async checkEmployee(optionSelected) {
      try {
        await this.$CanAddEmployees();
        if (optionSelected) {
          this.handleClick(optionSelected);
        } else {
          this.handleInvite();
        }
      } catch (error) {
        this.$toasted.error(
          "You have used up the number of employees/users subscribed for. Please contact your admin",
          {
            duration: 5000,
            action: {
              text: `${this.$AuthUser.isRoot ? "Manage Subscription" : ""}`,
              target: "_blank",
              href: `${process.env.VUE_APP_CLOUDENLY}management`,
            },
          }
        );
      }
    },

    async handleClick(optionSelected) {
      try {
        await this.$handlePrivilege("employeeDirectory", "addNewEmployee");
        if (optionSelected === "Create New") {
          this.$router.push({ name: "CreatePersonalInfo" });
        } else {
          this.openEmployeeFromPeople = true;
        }
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    async handleInvite() {
      try {
        await this.$handlePrivilege("employeeDirectory", "inviteEmployees");
        this.openInvitedEmployee = true;
      } catch (error) {
        // eslint-disable-next-line no-alert
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    subscriptionData() {
      this.$_getSubscriptionProduct().then((response) => {
        const allocatedUsers = response.data.numberOfAllocatedUsers;
        const { totalUsers } = response.data;
        this.allocatedUsers = allocatedUsers;
        this.totalUsers = totalUsers;

        const degreeAllocate = Math.floor((allocatedUsers / totalUsers) * 100);
        const degreeNonAllocate = 100 - degreeAllocate;

        this.varAllocated = `${degreeAllocate}`;
        this.varUnAllocated = `${degreeNonAllocate}`;

        this.strokeOffset = 25 + Number(this.varUnAllocated);

        this.allocatedTooltip = `Used Subscription Slot ${degreeAllocate}%`;
        this.unAllocatedTooltip = `Free Subscription Slot ${degreeNonAllocate}%`;
      });
    },
  },
  mounted() {
    this.subscriptionData();
  },
};
</script>
