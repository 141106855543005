<template>
  <RightSideBar
    v-if="openModal"
    @close="$emit('close'), (disableBtn = false)"
    submit="Proceed"
    @submit="handleSubmit"
    button-class="bg-dynamicBackBtn text-white"
    :disabled-button="disableBtn"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        Add Employee from Existing User/People
      </p>
    </template>
    <template v-slot:subtitle>
      <div class="flex w-full mb-5">
        <p>Is the employee information already saved here?</p>
        <p>
          Search existing People to add as an employee
        </p>
      </div>
    </template>
    <search-input class="mb-8" @input="makeSearch" placeholder="Search" />
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div
        v-for="(item, index) in showMore ? users : users.slice(0, 6)"
        :key="index"
        class="flex flex-col userContainer "
      >
        <div class="flex items-center">
          <div>
            <img
              class=""
              :src="item.photo"
              v-if="item.photo"
              alt="user photo"
              style="height: 30px; width: 30px; border-radius: 5px"
            />
            <div
              style="height: 35px; width: 35px; border-radius: 5px;"
              class="text-blueCrayola mr-3 flex justify-center
               border text-center font-semibold pt-1"
              v-else
            >
              {{ $getInitials(`${item.fname} ${item.lname}`) }}
            </div>
          </div>
          <div class="flex flex-col flex-grow">
            <p>
              {{ `${item.fname} ${item.lname}` }}
            </p>
            <div
              class="text-xs flex font-semibold w- text-romanSilver  uppercase "
            >
              <p class="opacity-50 mr-2">
                {{ ` ${item.email}` }}
              </p>
            </div>
          </div>
          <input type="radio" name="" :value="item.id" v-model="selectedItem" />
        </div>
      </div>
      <p
        class=" text-flame ml-4 mt-10 cursor-pointer"
        @click="showMore = !showMore"
      >
        {{ showMore ? "Show Less" : "Show All" }}
      </p>
    </div>
  </RightSideBar>
</template>

<script>
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import RightSideBar from "@/components/RightSideBar";

export default {
  components: {
    RightSideBar,
    SearchInput
  },

  props: {
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      disableBtn: false,
      selectedItem: "",
      users: [],
      showMore: false,
      loading: true
    };
  },
  watch: {
    openModal(value){
      if(value){
        this.getUsers("")
      }
    }
  },
  methods: {
    handleSubmit() {
      this.disableBtn = true;
      this.$_createEmployeeFromPeople({ userId: this.selectedItem })
        .then(result => {
          this.$router.push({
            name: "EditPersonalInfo",
            params: { id: result.data.user },
            query: { currentTab: "Personal Info" }
          });
          this.$toasted.success("Person successfully added as employee", {
            duration: 4000
          });
          this.disableBtn = false;
        })
        .catch((e) => {
        this.disableBtn = false;
        return e;
        });
    },
    makeSearch(value) {
      this.loading = true;
      this.showMore = false;
      clearTimeout(this.timeout);
      if (!value) {
        this.getUsers("");
        return;
      }
      const self = this;
      this.timeout = setTimeout(() => {
        self.$_getPeopleSearch(value).then(result => {
          if (!result.data.length) {
            self.users = [];
            self.loading = false;
            return;
          }
          self.users = result.data;
          self.loading = false;
        });
      }, 500);
    },
    getUsers(searchQuery) {
      this.loading = true
      this.$_getPeopleSearch(searchQuery).then(result => {
        this.users = result.data;
        this.loading = false;
      });
    }
  },
};
</script>

<style>
.userContainer {
  padding: 8px;
  border-bottom: 1px dashed #878e99;
}
input[type="radio"]:checked {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  outline: none;
  background-color: #f15a29 !important;
  box-shadow: 0px 0px 0px 2px #eeeeee inset;
}
</style>
