<template>
  <div class="mb-4">
    <h1 v-if="showTitle" class="w-full text-sm my-4">Employee Details</h1>
    <div class=" flex">
      <img
        class=""
        :src="userDetails.photo"
        v-if="userDetails.photo"
        alt="photo"
        style="min-height: 172px; width: 143px; border-radius: 5px; object-fit: cover;"
      />
      <div
        style="min-height: 172px; width: 143px; border-radius: 5px;"
        class="text-blueCrayola flex justify-center items-center border text-center font-semibold text-2xl"
        v-else
      >
        {{ $getInitials(`${userDetails.fname} ${userDetails.lname}`) }}
      </div>
      <div class="ml-3 -mt-1 mb-2">
        <p class=" font-bold text-lg mb-2 text-darkPurple">
          {{ `${userDetails.fname} ${userDetails.lname}` }}
        </p>
        <div>
          <div class="flex mb-2">
            <p
              style="width:140px;padding: 2px 0"
              class="text-xs uppercase font-semibold text-romanSilver"
            >
              Function:
            </p>
            <p class=" font-semibold text-sm text-jet">
              {{ userDetails.orgFunction ? userDetails.orgFunction.name : "-" }}
            </p>
          </div>
          <div class="flex mb-2">
            <p
              style=" width:140px; padding: 2px 0"
              class="text-xs uppercase font-semibold text-romanSilver"
            >
              Designation:
            </p>
            <p class=" font-semibold text-sm text-jet">
              {{
                userDetails.userDesignation
                  ? userDetails.userDesignation.name
                  : "-"
              }}
            </p>
          </div>
          <div class="flex mb-2">
            <p
              style=" width:140px; padding: 2px 0"
              class="text-xs uppercase font-semibold text-romanSilver"
            >
              Job level:
            </p>
            <p class=" font-semibold text-sm text-jet">
              {{ userDetails.userLevel ? userDetails.userLevel.name : "-" }}
            </p>
          </div>
          <div class="flex mb-2">
            <p
              style=" width:140px; padding: 2px 0"
              class="text-xs uppercase font-semibold text-romanSilver"
            >
              Office Location:
            </p>
            <p class=" font-semibold capitalize text-sm text-jet">
              {{
                userDetails.employeeOffice
                  ? userDetails.employeeOffice.name
                  : "-"
              }}
            </p>
          </div>
          <div class="flex mb-2">
            <p
              style="width:140px; padding: 2px 0"
              class="text-xs uppercase font-semibold text-romanSilver"
            >
              Manager:
            </p>
            <p class=" font-semibold text-sm text-jet">
              {{
                userDetails.userReportingTo
                  ? `${userDetails.userReportingTo.fname} ${userDetails.userReportingTo.lname} `
                  : "-"
              }}
            </p>
          </div>
          <div v-if="promote">
            <div class="flex my-2">
              <p
                style="width:140px; padding: 2px 0"
                class="text-xs uppercase font-semibold text-romanSilver"
              >
                Pay band:
              </p>
              <p class=" font-semibold text-sm text-jet">
                {{
                  userDetails.compensation
                    ? `${convertToCurrency(
                        userDetails.compensation.rangeMinimum
                      )} - ${convertToCurrency(
                        userDetails.compensation.rangeMaximum
                      )}`
                    : "-"
                }}
              </p>
            </div>
            <div class="flex">
              <p
                style="width:140px; padding: 2px 0"
                class="text-xs uppercase font-semibold text-romanSilver"
              >
                Gross Annual Salary:
              </p>
              <p class=" font-semibold capitalize text-sm text-jet">
                {{ convertToCurrency(userDetails.grossPay) }}
              </p>
            </div>
          </div>
          <div v-else class="flex">
            <p
              style=" width:140px; padding: 2px 0"
              class="text-xs uppercase font-semibold text-romanSilver"
            >
              Length of Service:
            </p>
            <p class=" font-semibold text-sm text-jet">
              {{
                userDetails.resumptionDate
                  ? formatDuration(
                      intervalToDuration({
                        start: new Date(userDetails.resumptionDate),
                        end: new Date()
                      }),
                      { format: ["years", "months", "days"] }
                    )
                  : "-"
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  formatDuration,
  intervalToDuration,
  differenceInYears
} from "date-fns";

export default {
  props: {
    userDetails: {
      type: Object,
      default() {
        return {};
      }
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    promote: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      formatDuration,
      intervalToDuration,
      differenceInYears
    };
  }
};
</script>

<style></style>
