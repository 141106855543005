<template>
  <ValidationObserver rules="required" v-slot="{ handleSubmit }">
    <RightSideBar
      v-if="isOpen"
      @submit="handleSubmit(reviseSalary)"
      @close="handleClose"
      :loading="isLoading"
      :disabled="isLoading"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <p class="text-darkPurple">
          Revise Employee Salary
        </p>
      </template>
      <template v-slot:subtitle>
        <employee-details promote :user-details="userDetails" />
      </template>
      <div>
        <div class="flex mb-4">
          <p class="text-jet text-sm mr-5">
            Effective Date
          </p>
        </div>
        <div class="date-flex">
          <datepicker
            placeholder="--Select Date--"
            input-class="date-input "
            style="width:100%;"
            v-model="revisePayload.effectiveDate"
          />
        </div>
        <divider border-dash class="my-4" />

        <p class="mb-5">Revise Salary By:</p>
        <scroll-container height="280px">
          <div
            style="background: #FFFFFF;
          border: 1px solid #878E99;
          box-sizing: border-box; border-radius: 5px;"
            class="p-3"
          >
            <radio-button
              :options="reviseType"
              col-span="col-span-4"
              row-gap="gap-2"
              class=" inline-block  text-darkPurple"
              @change="handleEligibilityChange"
              v-model="revisePayload.reviseBy"
            />
            <div class="grid grid-cols-12 gap-6 mt-8">
              <div class="col-span-6">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  label="Percentage"
                  :disabled="disablePercentInput"
                  v-model="revisePayload.percentage"
                />
              </div>
              <div class="col-span-6">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  label="Amount"
                  type="number"
                  :disabled="disableAmountInput"
                  v-model="revisePayload.amount"
                />
              </div>
              <div class="col-span-12">
                <c-text
                  placeholder=""
                  variant="w-full"
                  label="New Salary"
                  :disabled="true"
                  v-model="newSalary"
                  :rules="['required']"
                />
                <template v-if="userDetails && newAnnualSalary">
                  <template v-if="Object.keys(userDetails.compensation).length && newAnnualSalary">
                    <small class=" text-flame" v-if="newAnnualSalary < userDetails.compensation.rangeMinimum">
                      <span>* New annual gross salary is less than pay range</span>
                    </small>
                    <small class=" text-flame" v-if="newAnnualSalary > userDetails.compensation.rangeMaximum">
                      <span>* New annual gross salary is more than pay range</span>
                    </small>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </scroll-container>
      </div>
      <Modal v-if="isAdjustmentWarning" class="">
        <Card class=" p-2 px-3 flex flex-col gap-3 max-w-md">
          <template>
            <div class="border-b border-romanSilver">
              <p class="text-jet font-bold text-base">Action Required!</p>
            </div>
            <div class="w-full flex flex-col justify-start gap-2">
              <div class="relative mb-5 w-full">
                <div class="w-full px-5 py-2 flex self-center mb-2 rounded-md bg-yellow-100 border-carrotOrange border">
                  <div class="flex justify-start items-center gap-2">
                    <Icon icon-name="icon-info" size="xs" class="text-carrotOrange" />
                    <p class="text-carrotOrange w-full rounded-md text-center text-sm">
                      <span v-if="newAnnualSalary < userDetails.compensation.rangeMinimum">
                          New annual gross salary is less than pay range
                        </span>
                        <span v-if="newAnnualSalary > userDetails.compensation.rangeMaximum">
                          New annual gross salary is more than pay range
                        </span>
                    </p>
                  </div>
                </div>
                <div class="w-full flex flex-col justify-start items-start gap-1">
                  <div class="flex justify-start items-center gap-2">
                    <p class="font-semibold text-sm text-jet">Salary Range:</p>
                     <p class="font-bold text-sm leading-5 text-jet">
                      <span v-if="userDetails.compensation">
                        {{ convertToCurrency(userDetails.compensation.rangeMinimum) }}
                          -
                        {{ convertToCurrency(userDetails.compensation.rangeMaximum) }}
                      </span>
                      <span v-else>-</span>
                    </p>
                  </div>
                  <div class="flex justify-start items-center gap-2">
                    <p class="font-semibold text-sm text-jet">Current Annual Salary:</p>
                    <p class="font-bold text-sm text-jet">{{ convertToCurrency(userDetails.grossPay) }}</p>
                  </div>
                  <div class="flex justify-start items-center gap-2">
                    <p class="font-semibold text-sm text-jet">New Annual Salary:</p>
                    <p class="font-bold text-sm text-jet">{{ convertToCurrency(newAnnualSalary) }}</p>
                  </div>
                </div>
              </div>
              <p>Do you wish to continue?</p>
              <div class="flex justify-end items-center gap-2">
                <Button
                  class="button-class button-class-secondary"
                  label="Cancel"
                  @onClick="isAdjustmentWarning = false"
                />
                <Button
                  class="button-class button-class-pimary btn-bg"
                  label="Continue"
                  @onClick="reviseSalary"
                />
              </div>
            </div>
          </template>
        </Card>
      </Modal>
    </RightSideBar>
  </ValidationObserver>
</template>

<script>
import * as _ from "lodash"
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Button from "@scelloo/cloudenly-ui/src/components/button";
import Datepicker from "vuejs-datepicker";
import RightSideBar from "@/components/RightSideBar";
import Divider from "@/components/divider";
import RadioButton from "@/components/RadioButton";
import ScrollContainer from "@/components/ScrollContainer";
import Modal from "@/components/Modal";
import EmployeeDetails from "./employeeDetails";

export default {
  name: "ReviseEmployee",
  components: {
    ValidationObserver,
    RightSideBar,
    EmployeeDetails,
    Datepicker,
    RadioButton,
    Divider,
    CText,
    Card,
    Button,
    Modal,
    ScrollContainer
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isAdjustmentWarning: false,
      userDetails: [],
      newAnnualSalary: '',
      compensation: {},
      salaryBandRule: [
        "required",
        {
          name: "payband",
          rule: v => this.checkBandRange(v)
        }
      ],
      error: false,
      setNewSalary: "",
      disablePercentInput: true,
      disableAmountInput: false,
      revisePayload: {
        reviseBy: "amount",
        effectiveDate: new Date(),
        percentage: null,
        amount: null
      },
      reviseType: [
        {
          name: "Amount",
          radioName: "revise",
          value: "amount"
        },
        {
          name: "% increase",
          radioName: "revise",
          value: "percentage_increase"
        },
        {
          name: "% decrease ",
          radioName: "revise",
          value: "percentage_decrease"
        }
      ]
    };
  },
  computed: {
    /* eslint-disable vue/no-side-effects-in-computed-properties */
    newSalary() {
      let result = this.userDetails.grossPay;
      const { reviseBy, percentage, amount } = this.revisePayload;
      if (
        reviseBy === "percentage_increase" ||
        reviseBy === "percentage_decrease"
      ) {
        const per = Number(percentage);
        const percent = per / 100;
        const percentageSolved = percent * this.userDetails.grossPay;
        result =
          reviseBy === "percentage_increase"
            ? percentageSolved + this.userDetails.grossPay
            : this.userDetails.grossPay - percentageSolved;
      }
      if (reviseBy === "amount") {
        const amt = Number(amount);
        result = amt + this.userDetails.grossPay;
      }
      this.newAnnualSalary = result;
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },

  methods: {
    async toggle(payload) {
      this.isOpen = true
      this.isLoading = true

      await this.onGetPayBandByOrgParams({
        orgId: payload.orgId,
        levelId: payload.employeeLevelId,
        designationId: payload.designationId,
        functionId: payload.functionId,
        locationId: payload.officeId
      })
     this.userDetails = {...payload, compensation: this.compensation}

      this.isLoading = false
    },
    async onGetPayBandByOrgParams(payload){
      await this.$_getPaygradeByOrgParameters(payload)
      .then(({ data }) => {this.compensation = data.data ? data.data : {}})
        .catch((err) => {
          this.compensation = {};
        this.$toasted.error(err.message, {duration : 5000})})
    },
    checkBandRange(newSalary) {
      const salary = newSalary.split(",").join("");
      if (this.$store.state.subscription && this.$store.state.subscription.plan === "basic") {
        return true;
      }
      if (this.userDetails.compensation) {
        if (
          Number(salary) < this.userDetails.compensation.rangeMinimum ||
          Number(salary) > this.userDetails.compensation.rangeMaximum
        ) {
          return "Salary is not within user's pay range";
        }
      }
      return true;
    },
    handleClose() {
      this.openModal = false;
      this.isOpen = false;
      this.isAdjustmentWarning = false;
      this.revisePayload.effectiveDate = null;
      this.revisePayload.reviseBy = "amount";
      this.revisePayload.percentage = null;
      this.revisePayload.amount = null;
      this.disableAmountInput = false;
      this.disablePercentInput = true;
      this.$emit("close", this.newAnnualSalary);
    },
    handleEligibilityChange(value) {
      if (value === "percentage_increase" || value === "percentage_decrease") {
        this.disableAmountInput = true;
        this.disablePercentInput = false;
        this.revisePayload.amount = null;
      } else {
        this.disablePercentInput = true;
        this.disableAmountInput = false;
        this.revisePayload.percentage = null;
      }
    },
    reviseSalary() {
      const rangeMinimum = this.userDetails.compensation ? this.userDetails.compensation.rangeMinimum : 0;
      const rangeMaximum = this.userDetails.compensation ? this.userDetails.compensation.rangeMinimum : 0;

      const isInRange = _.inRange(this.newAnnualSalary, rangeMinimum, rangeMaximum)
      if(!isInRange && rangeMinimum && rangeMaximum && !this.isAdjustmentWarning){
        this.isAdjustmentWarning = true
        return undefined
      }
        this.revisePayload.userId = this.userDetails.userId;
        this.revisePayload.orgId = this.$AuthUser.orgId;
        this.revisePayload.effectiveDate = this.$DATEFORMAT(
          new Date(this.revisePayload.effectiveDate),
          "yyyy-MM-dd"
        );
        return this.$_employeeActions("revise-salary", this.revisePayload)
        .then(result => {
          this.$toasted.success(result.data.message, { duration: 5000 });
          this.handleClose();
        }).catch(err => {
          this.handleClose();
          throw new Error(err);
        });

    }
  },
  mounted() {}
};
</script>

<style lang="scss">
.rightSidebar {
  width: 520px !important;
}
.payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
    padding: 10px 15px;
    height: 40px;
  }
}
</style>

<style scoped>
.button_class {
  padding: 10px 40px !important;
  font-weight: 600;
  display: flex;
  justify-self: end;
}
.button-class-pimary {
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}
.button-class-secondary {
  color: #321c3b !important;
  background: white !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 10px 40px !important;
}
.alert-style {
  position: relative;
  right: 0; top: -5px;
  box-shadow: none;
  background: #FFFFFF;
  color: #E99323;
  font-size: 16px;
  font-weight: bold;
  border: 1px solid rgb(254 202 202);
}

.btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
</style>
