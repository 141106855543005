<template>
  <RightSideBar
    v-if="openModal"
    @submit="handleSubmit"
    @close="handleClose"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple">
        Resend Employee Credentials
      </p>
    </template>
    <div>
      <div
        style="background: #FFFFFF;
          box-sizing: border-box; border-radius: 5px;"
        class="p-3"
      >
        <radio-button
          :options="passwordType"
          col-span="col-span-6"
          row-gap="gap-2"
          class=" inline-block  text-darkPurple"
          @change="handleModeChange"
          v-model="selectedOption"
        />
        <div v-if="showPasswordInput" class="grid grid-cols-12 gap-6 mt-8">
          <div class="col-span-12">
            <c-text
              placeholder="--Enter--"
              variant="w-full"
              :rules="['required']"
              label="Password"
              v-model="credential.password"
            />
          </div>
        </div>
      </div>
    </div>
  </RightSideBar>
</template>

<script>
import CText from "@scelloo/cloudenly-ui/src/components/text";
import RightSideBar from "@/components/RightSideBar";
import RadioButton from "@/components/RadioButton";

export default {
  name: "ReviseEmployee",
  components: {
    RightSideBar,
    RadioButton,
    CText
  },
  props: {
    openModal: {
      type: Boolean,
      default: false
    },
    userDetails: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      error: false,
      showPasswordInput: false,
      selectedOption: "auto",
      credential: {
        autoGenerate: true,
        password: null
      },
      passwordType: [
        {
          name: "Auto-generate Password",
          radioName: "password",
          value: "auto"
        },
        {
          name: "Manually Set Password",
          radioName: "password",
          value: "manual"
        }
      ]
    };
  },

  methods: {
    handleClose() {
      this.credential.password = null;
      this.$emit("close");
    },
    handleModeChange(value) {
      if (value === "manual") {
        this.selectedOption = "manual";
        this.showPasswordInput = true;
        this.credential.autoGenerate = false;
      } else {
        this.showPasswordInput = false;
        this.selectedOption = "auto";
        this.credential.autoGenerate = true;
      }
    },
    handleSubmit() {
      this.credential.userId = this.userDetails.userId;
      this.$_employeeCredentialUpdate(this.credential)
        .then(() => {
          this.$toasted.success("Credentials updated successfully", {
            duration: 5000
          });
          this.handleClose();
        })
        .catch(err => {
          this.$toasted.error("Credentials not updated successfully", {
            duration: 5000
          });
          this.handleClose();
          throw new Error(err);
        });
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
.payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
    padding: 10px 15px;
    height: 40px;
  }
}
</style>
