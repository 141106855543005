<template>
  <RightSideBar
    v-if="openModal"
    :loading="loading"
    @submit="handleSubmit"
    submit="Submit"
    @close="handleClose"
    button-class="bg-dynamicBackBtn text-white"
  >
    <template v-slot:title>
      <p class="text-darkPurple">Promote Employee</p>
    </template>
    <template v-slot:subtitle>
      <employee-details promote :user-details="userDetails" />
    </template>
    <div>
      <div class="flex mb-4">
        <p class="text-jet text-sm mr-5">Effective Date</p>
      </div>
      <div class="date-flex">
        <datepicker
          placeholder="--Start Date--"
          input-class="date-input "
          style="width: 100%"
          v-model="promotePayload.effectiveDate"
        />
      </div>
      <divider border-dash class="my-4" />

      <div
        class="grid grid-cols-12 gap-4 text-darkPurple"
        style="margin-top: 1.125rem"
      >
        <div class="col-span-12">
          <c-select
            placeholder="--Select--"
            :options="setDesignation"
            label="New Designation"
            variant="w-full"
            v-model="promotePayload.newDesignationId"
          />
        </div>
        <div class="col-span-12">
          <div class="text-sm mb-2 text-jet">Job Level</div>
          <div class="payInfo payInfo_fakeInput text-center">
            {{ setLevel ? setLevel.name : "--Generated--" }}
          </div>
        </div>
        <div class="col-span-12">
          <div class="text-sm mb-2 text-jet">Pay Band/Range</div>
          <div class="payInfo payInfo_fakeInput text-center">
            {{ setPayscale || "--Generated--" }}
          </div>
        </div>
        <div class="col-span-12">
          <c-select
            placeholder="--Select--"
            :options="locationData"
            label="Office Location"
            variant="w-full"
            v-model="promotePayload.locationId"
          />
        </div>
        <div class="col-span-12">
          <c-select
            placeholder="--Select--"
            :options="managers"
            label="New Manager"
            variant="w-full"
            v-model="promotePayload.newManagerId"
          />
        </div>
        <div class="col-span-12">
          <c-text
            placeholder="--Enter--"
            label="New Gross Annual Salary(₦)"
            variant="w-full"
            v-model="setNewSalary"
          />
          <small v-if="error" class="text-flame"
            >New salary can't be more or less than salary band</small
          >
        </div>
      </div>
    </div>
  </RightSideBar>
</template>

<script>
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Datepicker from "vuejs-datepicker";
import RightSideBar from "@/components/RightSideBar";
import Divider from "@/components/divider";
import EmployeeDetails from "./employeeDetails";

export default {
  name: "PromoteEmployee",
  components: {
    RightSideBar,
    EmployeeDetails,
    Datepicker,
    Divider,
    CSelect,
    CText,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
    userDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      designations: [],
      designationPayscale: {},
      managers: [],
      payBand: [],
      error: false,
      locationData: [],
      setNewSalary: "",
      promotePayload: {
        effectiveDate: null,
        newDesignationId: null,
        newAnnualSalary: null,
        newManagerId: null,
        locationId: null,
      },
      loading: true,
    };
  },
  computed: {
    setDesignation() {
      return this.$store.state.org.getAllDesignations;
    },
    setLevel() {
      let result;
      if (this.setDesignation) {
        result = this.setDesignation.find(
          (item) => item.id === this.promotePayload.newDesignationId
        );
      }

      return result ? result.level : "";
    },

    setPayscale() {
      const response = this.getPayBandByDesignation();
      // this.designationPayscale = response

      return response
        ? `${this.convertToCurrency(
            response.lowerBand
          )} - ${this.convertToCurrency(response.upperBand)}`
        : null;
    },
  },
  watch: {
    setNewSalary(value) {
      const response = this.getPayBandByDesignation();
      if (response) {
        const { lowerBand, upperBand } = response;
        if (value >= lowerBand && value <= upperBand) {
          this.error = false;
        } else {
          this.error = true;
        }
      }

      return value;
    },
    async openModal(value) {
      if (value) {
        this.$store
          .dispatch("org/getDesignation", "").then(() => {this.loading = false});
        this.setManagers();
        const result = await this.$_getHumanarLocations();
        this.locationData = result.data.outlets;
        this.getPayBand();
      }
    },
  },
  methods: {
    getPayBandByDesignation() {
      let result;
      if (this.setDesignation) {
        result = this.setDesignation.find(
          (item) => item.id === this.promotePayload.newDesignationId
        );
      }

      return result
        ? this.payBand.find((level) => level.levelId === result.level.id)
        : null;
    },
    async setManagers() {
      try {
        const { data } = await this.$_getAllOrgUsers("");
        const managers = data.map((item) => ({
          id: item.id,
          name: `${item.fname} ${item.lname}`,
        }));
        this.managers = managers;
      } catch (err) {
        throw new Error(err);
      }
    },
    handleClose() {
      this.promotePayload.effectiveDate = null;
      this.promotePayload.newDesignationId = null;
      this.promotePayload.newAnnualSalary = null;
      this.promotePayload.newManagerId = null;
      this.setNewSalary = null;
      this.$emit("close");
    },
    handleSubmit() {
      this.promotePayload.userId = this.userDetails.userId;
      this.promotePayload.orgId = this.$AuthUser.orgId;
      this.promotePayload.newAnnualSalary = this.setNewSalary;
      this.promotePayload.newPayscale = this.designationPayscale;
      this.promotePayload.effectiveDate = this.$DATEFORMAT(
        new Date(this.promotePayload.effectiveDate),
        "yyyy-MM-dd"
      );
      this.$_employeeActions("promote", this.promotePayload)
        .then(() => {
          this.handleClose();
          this.$toasted.success("Employee promoted successfully", {
            duration: 3000,
          });
        })
        .catch((err) => {
          this.$toasted.error("Employee wasn't promoted Please try again", {
            duration: 3000,
          });
          throw new Error(err);
        });
    },

    getPayBand() {
      this.$_getOrgPayBand().then((result) => {
        const response = result.data.data;
        this.payBand = response.map((data) => ({
          name: data.payband.name,
          level: data.level.name,
          lowerBand: data.payband.lowerBand,
          upperBand: data.payband.upperBand,
          id: data.payband.id,
          levelId: data.payband.levelId,
        }));
      });
    },
  },
};
</script>

<style lang="scss">
.payInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  &_fakeInput {
    background: #f7f7ff;
    border-color: #878e99;
    padding: 10px 15px;
    height: 40px;
  }
}
</style>
